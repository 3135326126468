import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import { graphql } from "gatsby";
import { mainBlue, mainWhite, screen } from "../components/variables";

const Wrapper = styled.div`
  padding: 70px 0;
  background: ${mainWhite};
  min-height: 68vh;
  @media ${screen.xsmall} {
    padding: 70px 0 110px 0;
  }

  .title {
    font-size: 1.4rem;
    @media ${screen.xsmall} {
      font-size: 1.95rem;
    }
    @media ${screen.small} {
      font-size: 2.15rem;
    }
  }

  .description {
    max-width: 1010px;
    margin: 21px 0 0 0;
    @media ${screen.xsmall} {
      margin: 35px 0 0 0;
    }

    p {
      font-size: 0.95rem;
      margin: 0 0 20px 0;

      &:last-child {
        margin: 0;
      }
    }

    h1 {
      font-size: 1.7rem;
      margin: 0 0 10px 0;
      line-height: 1.4;
      @media ${screen.xsmall} {
        font-size: 1.8rem;
      }
      @media ${screen.small} {
        font-size: 1.9rem;
      }
    }

    h2 {
      font-size: 1.6rem;
      margin: 0 0 10px 0;
      line-height: 1.4;
      @media ${screen.xsmall} {
        font-size: 1.7rem;
      }
      @media ${screen.small} {
        font-size: 1.8rem;
      }
    }

    h3 {
      font-size: 1.5rem;
      margin: 0 0 10px 0;
      line-height: 1.4;
      @media ${screen.xsmall} {
        font-size: 1.6rem;
      }
      @media ${screen.small} {
        font-size: 1.7rem;
      }
    }

    h4 {
      font-size: 1.4rem;
      margin: 0 0 10px 0;
      line-height: 1.4;
      @media ${screen.xsmall} {
        font-size: 1.5rem;
      }
      @media ${screen.small} {
        font-size: 1.6rem;
      }
    }

    h5 {
      font-size: 1.3rem;
      margin: 0 0 10px 0;
      line-height: 1.4;
      @media ${screen.xsmall} {
        font-size: 1.4rem;
      }
      @media ${screen.small} {
        font-size: 1.5rem;
      }
    }

    h6 {
      font-size: 1.2rem;
      margin: 0 0 10px 0;
      line-height: 1.4;
      @media ${screen.xsmall} {
        font-size: 1.3rem;
      }
      @media ${screen.small} {
        font-size: 1.4rem;
      }
    }

    ul,
    ol {
      padding: 0 0 0 25px;
      margin: 40px 0;
      @media ${screen.xsmall} {
        padding: 0 0 0 40px;
      }
    }

    ol {
      li {
        list-style-type: disc;
      }
    }

    ul {
      li {
        list-style-type: decimal;
      }
    }

    li {
      font-size: 0.95rem;
      margin: 0 0 10px 0;
      line-height: 1.55;

      &:last-child {
        margin: 0;
      }
    }

    a {
      color: ${mainBlue};
      text-decoration: underline;
    }
  }
`;

const PrivacyPage = ({ data }) => {
  const { title, description, title_tag, meta_description } = data.content.data;

  return (
    <Layout rotatedSquareColour={mainWhite}>
      <Seo title={title_tag || title.text} description={meta_description} />
      <Wrapper>
        <div className="inner-wrapper">
          <h1 className="title">{title.text}</h1>
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: description.html }}
          />
        </div>
      </Wrapper>
    </Layout>
  );
};

export default PrivacyPage;

export const dataQuery = graphql`
  {
    content: prismicPrivacyPage {
      data {
        meta_description
        title_tag
        title {
          text
        }
        description {
          html
        }
      }
    }
  }
`;
